import React, { FC } from 'react';
import classnames from 'classnames';
import Img, { FixedObject } from 'gatsby-image';

import { IFixedImageProps } from './models';

import './Image.scss';

const ImageFixed: FC<IFixedImageProps> = ({
  className,
  alt,
  lazyLoad,
  mobileImage,
  tabletImage,
  desktopImage,
  desktopBreakpoint = 768,
}) => {
  const sources: FixedObject[] = [mobileImage.fixed];

  if (tabletImage?.fixed) {
    sources.push({
      ...tabletImage.fixed,
      media: `(min-width: 470px)`,
    });
  }

  if (desktopImage?.fixed) {
    sources.push({
      ...desktopImage.fixed,
      media: `(min-width: ${desktopBreakpoint}px)`,
    });
  }

  return (
    <div
      className={classnames('dt-image dt-image--fixed', {
        [`${className}`]: className,
      })}
    >
      <Img loading={lazyLoad ? 'lazy' : 'eager'} className="dt-image" fixed={sources} alt={alt} />
    </div>
  );
};

export default ImageFixed;
