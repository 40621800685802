import React, { FC } from 'react';
import { Link } from 'gatsby';
import Card from 'react-bootstrap/Card';
import classNames from 'classnames';
import Image from 'components/common/Image';
import PlainImage from 'components/common/Image/PlainImage';

import './ProductItem.scss';
import ImageFixed from '../Image/ImageFixed';

const ProductItem: FC<ProductTypes.IProduct> = ({
  isHovered,
  link,
  localHeroImage,
  heroImage,
  name,
  size,
  ean,
  sku,
  showRating,
  recognisableFeature,
  useFixedProductImage,
}) => (
  <div
    className={classNames('dt-product-item', {
      'dt-product-item--bg': isHovered,
    })}
  >
    <div className="dt-product-item__holder">
      <Link to={link}>
        <Card>
          <span className="dt-product-item__img">
            {useFixedProductImage && localHeroImage?.fixedMobileRelatedImage ? (
              <ImageFixed
                mobileImage={localHeroImage.fixedMobileRelatedImage}
                desktopImage={localHeroImage?.fixedDesktopRelatedImage}
                desktopBreakpoint={992}
                alt={name}
                className="dt-image__product-item--fixed"
              />
            ) : localHeroImage ? (
              <Image localImage={localHeroImage} alt={name} />
            ) : heroImage ? (
              <PlainImage lazyLoad src={heroImage} alt={name} />
            ) : null}
          </span>
          {showRating ? <div data-bv-show="inline_rating" data-bv-product-id={ean || sku} /> : null}
          <Card.Body>
            <Card.Title>{`${name} ${size}`}</Card.Title>
            <Card.Text>{recognisableFeature}</Card.Text>
          </Card.Body>
        </Card>
      </Link>
    </div>
  </div>
);

export default ProductItem;
