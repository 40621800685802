import React, { FC } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import ProductItem from 'components/common/ProductItem/ProductItem';
import { IProductList } from './models';
import './ProductList.scss';

const ProductListItems: FC<IProductList> = ({
  showRating = false,
  products,
  btn,
  isHovered,
  handleLoadMore,
  useFixedProductImage,
}) => (
  <div className="dt-product-list">
    <div className="dt-product-list__holder">
      <Row>
        {products.map((item) => (
          <Col key={item.sku} className="dt-product-list__col" md={3}>
            <ProductItem
              {...item}
              showRating={showRating}
              isHovered={isHovered}
              useFixedProductImage={useFixedProductImage}
            />
          </Col>
        ))}
      </Row>
    </div>

    {btn ? (
      <div className="dt-product-list__btn-holder">
        <Button variant="outline-primary" className="dt-btn-mw-208" onClick={handleLoadMore}>
          {btn.btnText}
        </Button>
      </div>
    ) : null}
  </div>
);
export default ProductListItems;
